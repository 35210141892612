// react
import { useMemo } from 'react'
// hooks
import useSWR from 'swr'
import useAuth from '~/hooks/useAuth'
// utils
import { recursivelyProcessUserToken } from '~/libs/userHelper'

// ----------------------------------------------------------------------

/**
 * @typedef CreateSWRHookParams
 * @property {string} key
 * unique swr key prefix, can be anything that identify fetched data type
 * @property {(...args: any[]) => Promise<import('axios').AxiosResponse>} fetcher
 * fetcher function, must accept token as last params
 * **PITFALL**: make sure that the function.length will return a valid count of arguments,
 * since the hooks need it to apply token as last params
 * ```
 * // assume the the function service
 * const serviceCall = (arg, token) => fetch(...)
 *
 * // assume fetcher1, fetcher2, fetcher3 will be used for fetcher params
 * const fetcher1 = (...args) => serviceCall.getList(args)
 * console.log(fetcher.length) // prints 0, will not work
 *
 * const fetcher2 = (arg, token) => serviceCall(arg, token)
 * console.log(fetcher.length) // prints 2, will work
 * const fetcher3 = serviceCall
 * console.log(fetcher.length) // prints 2, will work
 * ```
 * @property {(res: import('axios').AxiosResponse) => any} [adapter]
 * function to transform fetch response,  type `(AxiosResponse) => any`. Default to `res => res.data.results`
 * @property {'always'|'when-available'|'never'} [withAuth]
 * include auth token in fetch request, available values `'always'|'when-available'|'never'`, also delay fecthing until user if available when when set no `always` and `when-available`. Default to `'when-available'`
 * @property {import('swr').SWRConfiguration} [swrConfig]
 * SWR config override, @see https://swr.vercel.app/docs/options#options
 */

/**
 * @typedef SWRResponseExtension
 * @property {any[]} key unique key for the request, used as parameter for the fetcher function
 * @property {boolean} withAuth auth requirement for the request, directly passed from the params
 * @property {boolean} isAuthPending request is blocked by auth pending state, when request is using `withAuth` set to `when-available` or `always`
 * @property {boolean} isAuthBlocked request is blocked by auth state, when request is using `withAuth` set to `always` and auth is not available
 * @property {boolean} isLegacyLoading available for backward compatibility, will be deprecated in the future. Behave exactly like `isLoading` in previous version (`createFetchingSWR`)
 */

/**
 * SWRResponse documentation
 * @typedef SWRResponseDocumentation
 * @property {import('swr').SWRResponse['isLoading']} isLoading passed from SWRResponse as it is, only true when fetching is in progress and no existing/stale data for this key is available, or request is pending due due to waiting for auth initialization
 * @property {import('swr').SWRResponse['isValidating']} isValidating passed from SWR response as it is, will be true when fetching is in progress, even when existing/stale data for this key is available
 * @property {import('axios').AxiosError} error
 */

/**
 *
 * @typedef SWRAppliedParams
 * @property {boolean} isArgsContainUserToken
 * @property {any[]} appliedArgs
 * @property {CreateSWRHookParams['withAuth']} appliedWithAuth
 * @property {boolean} isAuthPending
 * @property {boolean} isAuthBlocked
 * @property {any[]} swrKey
 */

/**
 * @typedef {Object} GetSWRAppliedParamsInput
 * @property {Object} authUser
 * @property {boolean} authIsInitialized
 * @property {boolean} authIsAuthenticated
 * @property {any[]} fetcherArgs
 */

/**
 * @typedef {(params: GetSWRAppliedParamsInput) => SWRAppliedParams} GetSWRAppliedParams
 */

/**
 * @typedef {(...args: any[] | [null]) => CustomSWRResponse} CustomSWRHookFn
 * @typedef {CustomSWRHookFn & { getAppliedParams: GetSWRAppliedParams } CustomSWRHook
 */

/**
 * @typedef {Omit<import('swr').SWRResponse, 'isLoading' | 'isValidating' | 'error'> & SWRResponseExtension & SWRResponseDocumentation} CustomSWRResponse
 */

/**
 * This is a new version of `createFetchingSWR` that extends the response from "state machine" of SWR.
 * @see https://swr.vercel.app/docs/advanced/understanding.en-US#state-machine
 * 
 * TLDR for changes in this version: returned data now extends from SWRResponse, with additional fields below:
 * - Returned `key` is added to indicate the unique key for the request, used as parameter for the fetcher function
 * - Returned `withAuth` is added to indicate the auth requirement for the request, directly passed from the params
 * - Returned `isAuthPending` is added to indicate that the request is blocked by auth pending state, when request is using `withAuth` set to `when-available` or `always`
 * - Returned `isAuthBlocked` is added to indicate that the request is blocked by auth state, when request is using `withAuth` set to `always` and auth is not available
 * - Returned `isLoading` is now directly passed from SWRResponse as it is, also accounts for auth pending state for swr hook `withAuth` set to `when-available` or `always` 
 * - Returned `isValidating` is now available, directly passed from SWRResponse as it is
 *
 * ---
 * 
 * Create an SWR hooks to fetch data, with some magic ✨
 * 1. Automatically delay data fetching depend on authentication requirement,
 *    see `withAuth` params
 * 2. If you need to require current user data in your fetching params,
 *    you can use special token `$user`, that will be resolved to user data in `AuthContext`
 * @example
 * ```
 * const useUserProductList = createFetchingSWR('user-product', getUserProductList)
 *
 * function SampleComponent() {
 *   const {data, isLoading} = useUserProductList('$user._id')
 *   console.log(data)
 *   // print user product list for current user
 *
 *   return ...
 * }
 * ```
 *
 * ---
 * 
 * @param {CreateSWRHookParams} params
 * @param {string} params.key
 * unique swr key prefix, can be anything that identify fetched data type
 * @param {(...args: any[]) => Promise<any>} params.fetcher

 * @param {(res: import('axios').AxiosResponse) => any} [params.adapter]
 * function to transform fetch response,  type `(res: AxiosResponse) => any`. Default to `(res) => res.data.results`
 * @param {'always'|'when-available'|'never'} [params.withAuth]
 * include auth token in fetch request, available values `'always'|'when-available'|'never'`,
 * also delay fecthing until user if available when when set no `always` and `when-available`.
 * Default to `'when-available'`
 * @param {import('swr').SWRConfiguration} [params.SWRConfig]
 * SWR config override, @see https://swr.vercel.app/docs/options#options
 * @returns {CustomSWRHookFn}
 */
export function createSWRHook({
  key,
  fetcher,
  adapter = (res) => res.data.results,
  withAuth = 'when-available',
  swrConfig = {}
}) {
  /**
   * Utility function to process the user token and return the applied parameters for SWR later
   * Why defined here? Not inside the hook? So we can call it without actually calling the hook
   * Use case: helper hook for get `mutate` function, that need to know the applied arguments exactly as used in the SWR.
   * So we can get the `mutate` function anywhere, without props passing from the hook return value
   * @type {GetSWRAppliedParams}
   */
  const getAppliedParams = (params) => {
    const [isArgsContainUserToken, appliedArgs] = recursivelyProcessUserToken(
      params.authUser,
      params.fetcherArgs
    )

    // check if withAuth override is needed, when token is found, then auth is always required
    const appliedWithAuth = isArgsContainUserToken ? 'always' : withAuth

    // check if auth is pending or blocked
    const isAuthPending =
      (appliedWithAuth === 'when-available' || appliedWithAuth === 'always') &&
      !params.authIsInitialized
    const isAuthBlocked =
      appliedWithAuth === 'always' && !params.authIsAuthenticated

    const swrKey =
      // if swr arguments is explicitly null, then prevent fetch by setting swrKey to null
      (params.fetcherArgs.length === 1 && params.fetcherArgs[0] === null) ||
      // if swr might require auth and auth is not initialized, then wait until it's initialized
      // while waiting, prevent fetch by setting swrKey to null
      isAuthPending ||
      isAuthBlocked
        ? null
        : [key, appliedArgs, params.authUser?._id ?? 'public']

    return {
      // args
      appliedArgs,
      isArgsContainUserToken,
      // auth
      appliedWithAuth,
      isAuthPending,
      isAuthBlocked,
      // swr
      swrKey
    }
  }

  /**
   * @param {any[]} args
   * @returns {CustomSWRResponse}
   */
  const useCustomSWR = (...args) => {
    const { getToken, isInitialized, isAuthenticated, user } = useAuth()

    /**
     * Validate the parameters and return the processed value
     * @type {ValidatedParams}
     */
    const {
      appliedWithAuth,
      isAuthPending,
      isAuthBlocked,
      //
      swrKey
      // below return value is not used in this version, but kept just in case
      // isTokenFound,
    } = useMemo(() => {
      return getAppliedParams({
        authUser: user,
        authIsInitialized: isInitialized,
        authIsAuthenticated: isAuthenticated,
        fetcherArgs: args
      })
      // process all `$user.*` token in the arguments by resolving it with user data in `AuthContext`
    }, [user, isInitialized, isAuthenticated, args])

    // calculate swr key that will be used for the request
    // note this intentionally not memoized, since it's will be serialized by SWR later anyway

    // define the swr hook
    const swr = useSWR(
      swrKey,
      async (appliedKey) => {
        // get token when withAuth is always or when-available
        const token =
          appliedWithAuth === 'always' || appliedWithAuth === 'when-available'
            ? await getToken()
            : null

        // make sure arguments length is correct
        const appliedFetcherArgs = [...Array(fetcher.length)].map(
          (_, i) => appliedKey[1][i] ?? null
        )
        appliedFetcherArgs[fetcher.length - 1] = token

        // return the actual fetching result
        return fetcher(...appliedFetcherArgs).then((res) => adapter(res))
      },
      swrConfig
    )

    // custom response addition
    const isLegacyLoading =
      // additional condition for loading when one of those is true,
      // besides than no data and no error
      // - auth might be required (when-available|always) and auth is not initialized
      // - auth is not requred
      (!swrKey &&
        (appliedWithAuth === 'when-available' ||
          appliedWithAuth === 'always') &&
        !isInitialized) ||
      (swrKey &&
        typeof swr.data === 'undefined' &&
        typeof swr.error === 'undefined')

    return {
      ...swr,
      key: swrKey,
      withAuth: appliedWithAuth,
      isAuthPending,
      isAuthBlocked,
      isLegacyLoading,
      isLoading: swr.isLoading || isAuthPending
    }
  }

  useCustomSWR.getAppliedParams = getAppliedParams

  return useCustomSWR
}

// ----------------------------------------------------------------------
// BELOW IS OLD VERSION (DEPRECATED), WILL BE DELETED IN THE FUTURE
// FOR NOW, IT'S KEEP FOR REFERENTIAL PURPOSES ONLY

/**
 * @deprecated - This function will be deprecated in the future, use `createSWRHook` instead
 *
 * Create an SWR hooks to fetch data, with some magic ✨
 * 1. Automatically delay data fetching depend on authentication requirement,
 *    see `withAuth` params
 * 2. If you need to require current user data in your fetching params,
 *    you can use special token `$user`, that will be resolved to user data in `AuthContext`
 * @example
 * ```
 * const useUserProductList = createFetchingSWR('user-product', getUserProductList)
 *
 * function SampleComponent() {
 *   const {data, isLoading} = useUserProductList('$user._id')
 *   console.log(data)
 *   // print user product list for current user
 *
 *   return ...
 * }
 * ```
 *
 * @param {Object} params
 * @param {string} params.key
 * unique swr key prefix, can be anything that identify fetched data type
 * @param {(...args: any[]) => Promise<import('axios').AxiosResponse>} params.fetcher
 * fetcher function, must have token as last params,
 * **PITFALL**: make sure that the function.length will return a valid count of arguments,
 * since the hooks need it to apply token as last params
 * ```
 * // assume the the function service
 * const serviceCall = (arg, token) => fetch(...)
 *
 * // assume fetcher1, fetcher2, fetcher3 will be used for fetcher params
 * const fetcher1 = (...args) => serviceCall.getList(args)
 * console.log(fetcher.length) // prints 0, will not work
 *
 * const fetcher2 = (arg, token) => serviceCall(arg, token)
 * console.log(fetcher.length) // prints 2, will work
 * const fetcher3 = serviceCall
 * console.log(fetcher.length) // prints 2, will work
 * ```
 * @param {(res: import('axios').AxiosResponse)=> any} [params.adapter]
 * function to transform fetch response,  type `(AxiosResponse) => any`, default to `res => res.data.results`
 * @param {'always'|'when-available'|'never'} [params.withAuth]
 * include auth token in fetch request, available values `'always'|'when-available'|'never'`,
 * also delay fecthing until user if available when when set no `always` and `when-available`, default to `'when-available'`
 * @param {import('swr').SWRConfiguration} [params.SWRConfig]
 * SWR config override, @see https://swr.vercel.app/docs/options#options
 * @returns {(...args: any[]| [null]) => {
 *  data: any,
 *  error: any,
 *  isLoading: boolean,
 *  key: any[],
 *  mutate: import('swr').SWRResponse['mutate'],
 * }}
 */
export function createFetchingSWR({
  key,
  fetcher,
  adapter = (res) => res.data.results,
  withAuth = 'when-available',
  SWRConfig = {}
}) {
  const useCustomizedSWR = (...args) => {
    const { getToken, isInitialized, isAuthenticated, user } = useAuth()

    // request is clear when all user token is succesfully processed (if any)
    const [isTokenFound, appliedArgs] = recursivelyProcessUserToken(user, args)
    const appliedWithAuth = isTokenFound ? 'always' : withAuth

    const swrKey =
      // if swr arguments is explicitly null, then prevent fetch by setting swrKey to null
      (args.length === 1 && args[0] === null) ||
      // if swr might require auth and auth is not initialized, then wait until it's initialized
      // while waiting, prevent fetch by setting swrKey to null
      (appliedWithAuth === 'when-available' && !isInitialized) ||
      (appliedWithAuth === 'always' && (!isInitialized || !isAuthenticated))
        ? null
        : [key, appliedArgs, user?._id ?? 'public']

    const swr = useSWR(
      swrKey,
      async (appliedKey) => {
        // get token when withAuth is always or when-available
        const token =
          appliedWithAuth === 'always' || appliedWithAuth === 'when-available'
            ? await getToken()
            : null

        // make sure arguments length is correct
        const appliedFetcherArgs = [...Array(fetcher.length)].map(
          (_, i) => appliedKey[1][i] ?? null
        )
        appliedFetcherArgs[fetcher.length - 1] = token

        // return the actual fetching result
        return fetcher(...appliedFetcherArgs).then((res) => adapter(res))
      },
      SWRConfig
    )

    return {
      ...swr,
      isLoading:
        // additional condition for loading when one of those is true,
        // besides than no data and no error
        // - auth might be required (when-available|always) and auth is not initialized
        // - auth is not requred
        (!swrKey &&
          (appliedWithAuth === 'when-available' ||
            appliedWithAuth === 'always') &&
          !isInitialized) ||
        (swrKey &&
          typeof swr.data === 'undefined' &&
          typeof swr.error === 'undefined'),
      key: swrKey
    }
  }

  useCustomizedSWR.key = key
  useCustomizedSWR.withAuth = withAuth

  return useCustomizedSWR
}
